import DISCUSSION_FORUM_MODAL_IDS from "../Discussion/constants";

export default {
  data() {
    return {
      DISCUSSION_FORUM_MODAL_IDS: {},
      reportType: "",
      reportId: ""
    };
  },
  created() {
    this.DISCUSSION_FORUM_MODAL_IDS = DISCUSSION_FORUM_MODAL_IDS;
  },
  methods: {
    showModal(modalId) {
      this.$bvModal.show(modalId);
    },
    showDeletePostModal() {
      this.showModal(this.DISCUSSION_FORUM_MODAL_IDS.DELETE_POST_MODAL);
    },
    showReportModal(type, id) {
      this.reportType = type;
      if (id) {
        this.reportId = id;
      }
      this.showModal(this.DISCUSSION_FORUM_MODAL_IDS.REPORT_POST_MODAL);
    }
  }
};
